import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_1bdvjq3 } from './call-center/handovers';
import type { Methods as Methods_1haym4v } from './call-center/handovers/_handover_id@number';
import type { Methods as Methods_634ubn } from './cloudfront/pre_signed_url';
import type { Methods as Methods_10rmbxm } from './excel/funerals';
import type { Methods as Methods_1gvlo7t } from './excel/master/areas';
import type { Methods as Methods_t3cxen } from './excel/master/areas/_area_id/subareas';
import type { Methods as Methods_17u15hq } from './excel/master/carrier_conditions';
import type { Methods as Methods_oqpsdt } from './excel/master/centers';
import type { Methods as Methods_h89pt6 } from './excel/master/centers/_center_id/hospitals';
import type { Methods as Methods_5a3j1a } from './excel/master/centers/_center_id/mortuaries';
import type { Methods as Methods_r3d1kv } from './excel/master/halls';
import type { Methods as Methods_1ndvyd0 } from './excel/master/japan_agricultural_branches';
import type { Methods as Methods_1ndru9w } from './excel/master/japan_agriculturals';
import type { Methods as Methods_1azbn2y } from './excel/master/partners';
import type { Methods as Methods_1sdohsv } from './excel/master/phone_lines';
import type { Methods as Methods_xvwcs0 } from './excel/master/phone_lines/_phone_line_id/attentions';
import type { Methods as Methods_1lvppy } from './excel/master/regions';
import type { Methods as Methods_q5smer } from './excel/master/stores';
import type { Methods as Methods_1w1bjs2 } from './excel/summary';
import type { Methods as Methods_15s9tzs } from './excel/tasks';
import type { Methods as Methods_1f5iie2 } from './excel/tasks/_task_id@number';
import type { Methods as Methods_hkcaqm } from './excel/users';
import type { Methods as Methods_14h2m12 } from './funerals';
import type { Methods as Methods_3g0ibe } from './funerals/_funeral_id@number';
import type { Methods as Methods_idk8rz } from './login';
import type { Methods as Methods_f8gur9 } from './master/areas';
import type { Methods as Methods_6s0wk7 } from './master/areas/_area_id@number';
import type { Methods as Methods_1930eo4 } from './master/areas/_area_id@number/subareas';
import type { Methods as Methods_einugw } from './master/areas/_area_id@number/subareas/_subarea_id@number';
import type { Methods as Methods_1at0fzp } from './master/centers';
import type { Methods as Methods_101iddb } from './master/centers/_center_id@number';
import type { Methods as Methods_14r7ajj } from './master/centers/_center_id@number/hospitals';
import type { Methods as Methods_18ahdeq } from './master/centers/_center_id@number/hospitals/_hospital_id@number';
import type { Methods as Methods_ri1fjl } from './master/centers/_center_id@number/mortuaries';
import type { Methods as Methods_mz0s4b } from './master/centers/_center_id@number/mortuaries/_mortuary_id@string';
import type { Methods as Methods_fw90za } from './master/centers/_center_id@number/stores';
import type { Methods as Methods_1pi6nhv } from './master/halls';
import type { Methods as Methods_rtsuef } from './master/halls/_hall_id@number';
import type { Methods as Methods_11aore8 } from './master/japan_agricultural_branches';
import type { Methods as Methods_r3exkg } from './master/japan_agricultural_branches/_japan_agricultural_branch_id@number';
import type { Methods as Methods_armftk } from './master/japan_agriculturals';
import type { Methods as Methods_7kf0jx } from './master/japan_agriculturals/_japan_agricultural_id@number';
import type { Methods as Methods_1ox7cf2 } from './master/partners';
import type { Methods as Methods_i2h7wn } from './master/partners/_partner_id@number';
import type { Methods as Methods_1wen3jc } from './master/partners/_partner_id@number/phone_lines';
import type { Methods as Methods_11nfbyz } from './master/phone_lines';
import type { Methods as Methods_2l6msf } from './master/phone_lines/_phone_line_id@number';
import type { Methods as Methods_znfq1a } from './master/phone_lines/_phone_line_id@number/areas';
import type { Methods as Methods_1857mpr } from './master/phone_lines/_phone_line_id@number/attentions';
import type { Methods as Methods_uikj3k } from './master/phone_lines/_phone_line_id@number/attentions/_attention_id@number';
import type { Methods as Methods_mvsdvo } from './master/phone_lines/_phone_line_id@number/call_system_memos';
import type { Methods as Methods_1a2svqw } from './master/phone_lines/_phone_line_id@number/call_system_memos/_call_system_memo_id@number';
import type { Methods as Methods_1h53rv } from './master/phone_lines/_phone_line_id@number/carrier_conditions';
import type { Methods as Methods_nilja0 } from './master/phone_lines/_phone_line_id@number/carrier_conditions/_carrier_condition_id@number';
import type { Methods as Methods_ufabey } from './master/phone_lines/_phone_line_id@number/carrier_conditions/_carrier_condition_id@number/update_display_order';
import type { Methods as Methods_1y9h1vx } from './master/phone_lines/_phone_line_id@number/carriers';
import type { Methods as Methods_1fdpwzk } from './master/phone_lines/_phone_line_id@number/carriers/_carrier_id@number';
import type { Methods as Methods_s5xq20 } from './master/phone_lines/_phone_line_id@number/halls';
import type { Methods as Methods_psvpqz } from './master/phone_lines/_phone_line_id@number/japan_agriculturals';
import type { Methods as Methods_1xzy7cu } from './master/phone_lines/_phone_line_id@number/japan_agriculturals/_japan_agricultural_id@number/japan_agricultural_branches';
import type { Methods as Methods_157ax5z } from './master/phone_lines/_phone_line_id@number/manuals';
import type { Methods as Methods_1p0k656 } from './master/phone_lines/_phone_line_id@number/manuals/_manual_id@number';
import type { Methods as Methods_1yhs3hd } from './master/phone_lines/_phone_line_id@number/mortuaries';
import type { Methods as Methods_13vl0sn } from './master/phone_lines/_phone_line_id@number/phone_line_partners';
import type { Methods as Methods_jdl2ui } from './master/phone_lines/_phone_line_id@number/phone_line_partners/_phone_line_partner_id@number';
import type { Methods as Methods_hug3gi } from './master/phone_lines/_phone_line_id@number/report_destinations';
import type { Methods as Methods_1qbrw5o } from './master/phone_lines/_phone_line_id@number/report_destinations/_report_destination_id@number';
import type { Methods as Methods_g2msg6 } from './master/phone_lines/_phone_line_id@number/stores';
import type { Methods as Methods_1s750dm } from './master/regions';
import type { Methods as Methods_1i7dnf1 } from './master/regions/_region_id@number';
import type { Methods as Methods_12xkumf } from './master/stores';
import type { Methods as Methods_12fgb13 } from './master/stores/_store_id@number';
import type { Methods as Methods_hstpwj } from './pdf/tasks/_task_id@number';
import type { Methods as Methods_w6o6q4 } from './tasks';
import type { Methods as Methods_dizqkm } from './tasks/_task_id@number';
import type { Methods as Methods_tli9od } from './user';
import type { Methods as Methods_c5f1rd } from './user-roles';
import type { Methods as Methods_1xhiioa } from './users';
import type { Methods as Methods_8ls8pm } from './users/_user_id@number';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://api.sosai-sys.com/api' : baseURL).replace(/\/$/, '');
  const PATH0 = '/call-center/handovers';
  const PATH1 = '/cloudfront/pre_signed_url';
  const PATH2 = '/excel/funerals';
  const PATH3 = '/excel/master/areas';
  const PATH4 = '/subareas';
  const PATH5 = '/excel/master/carrier_conditions';
  const PATH6 = '/excel/master/centers';
  const PATH7 = '/hospitals';
  const PATH8 = '/mortuaries';
  const PATH9 = '/excel/master/halls';
  const PATH10 = '/excel/master/japan_agricultural_branches';
  const PATH11 = '/excel/master/japan_agriculturals';
  const PATH12 = '/excel/master/partners';
  const PATH13 = '/excel/master/phone_lines';
  const PATH14 = '/attentions';
  const PATH15 = '/excel/master/regions';
  const PATH16 = '/excel/master/stores';
  const PATH17 = '/excel/summary';
  const PATH18 = '/excel/tasks';
  const PATH19 = '/excel/users';
  const PATH20 = '/funerals';
  const PATH21 = '/login';
  const PATH22 = '/master/areas';
  const PATH23 = '/master/centers';
  const PATH24 = '/stores';
  const PATH25 = '/master/halls';
  const PATH26 = '/master/japan_agricultural_branches';
  const PATH27 = '/master/japan_agriculturals';
  const PATH28 = '/master/partners';
  const PATH29 = '/phone_lines';
  const PATH30 = '/master/phone_lines';
  const PATH31 = '/areas';
  const PATH32 = '/call_system_memos';
  const PATH33 = '/carrier_conditions';
  const PATH34 = '/update_display_order';
  const PATH35 = '/carriers';
  const PATH36 = '/halls';
  const PATH37 = '/japan_agriculturals';
  const PATH38 = '/japan_agricultural_branches';
  const PATH39 = '/manuals';
  const PATH40 = '/phone_line_partners';
  const PATH41 = '/report_destinations';
  const PATH42 = '/master/regions';
  const PATH43 = '/master/stores';
  const PATH44 = '/pdf/tasks';
  const PATH45 = '/tasks';
  const PATH46 = '/user';
  const PATH47 = '/user-roles';
  const PATH48 = '/users';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';

  return {
    call_center: {
      handovers: {
        _handover_id: (val2: number) => {
          const prefix2 = `${PATH0}/${val2}`;

          return {
            /**
             * 引き継ぎの編集を行う
             */
            put: (option: { body: Methods_1haym4v['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1haym4v['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 引き継ぎの編集を行う
             */
            $put: (option: { body: Methods_1haym4v['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1haym4v['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 引き継ぎ詳細取得を行う
             * @returns 引き継ぎ詳細取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1haym4v['get']['resBody'], BasicHeaders, Methods_1haym4v['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 引き継ぎ詳細取得を行う
             * @returns 引き継ぎ詳細取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1haym4v['get']['resBody'], BasicHeaders, Methods_1haym4v['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 引き継ぎの削除を行う
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_1haym4v['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 引き継ぎの削除を行う
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_1haym4v['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 引き継ぎの登録を行う
         * @returns Created
         */
        post: (option: { body: Methods_1bdvjq3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1bdvjq3['post']['resBody'], BasicHeaders, Methods_1bdvjq3['post']['status']>(prefix, PATH0, POST, option).json(),
        /**
         * 引き継ぎの登録を行う
         * @returns Created
         */
        $post: (option: { body: Methods_1bdvjq3['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1bdvjq3['post']['resBody'], BasicHeaders, Methods_1bdvjq3['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
        /**
         * 引き継ぎ一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_1bdvjq3['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1bdvjq3['get']['resBody'], BasicHeaders, Methods_1bdvjq3['get']['status']>(prefix, PATH0, GET, option).json(),
        /**
         * 引き継ぎ一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_1bdvjq3['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1bdvjq3['get']['resBody'], BasicHeaders, Methods_1bdvjq3['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1bdvjq3['get']['query'] } | undefined) =>
          `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    cloudfront: {
      pre_signed_url: {
        /**
         * 署名付きURL生成を行う。
         * @returns 搬送業者登録成功
         */
        post: (option: { body: Methods_634ubn['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_634ubn['post']['resBody'], BasicHeaders, Methods_634ubn['post']['status']>(prefix, PATH1, POST, option).json(),
        /**
         * 署名付きURL生成を行う。
         * @returns 搬送業者登録成功
         */
        $post: (option: { body: Methods_634ubn['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_634ubn['post']['resBody'], BasicHeaders, Methods_634ubn['post']['status']>(prefix, PATH1, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`,
      },
    },
    excel: {
      funerals: {
        /**
         * 葬儀一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        get: (option?: { query?: Methods_10rmbxm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_10rmbxm['get']['resBody'], BasicHeaders, Methods_10rmbxm['get']['status']>(prefix, PATH2, GET, option).blob(),
        /**
         * 葬儀一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        $get: (option?: { query?: Methods_10rmbxm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_10rmbxm['get']['resBody'], BasicHeaders, Methods_10rmbxm['get']['status']>(prefix, PATH2, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_10rmbxm['get']['query'] } | undefined) =>
          `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      master: {
        areas: {
          _area_id: (val3: number | string) => {
            const prefix3 = `${PATH3}/${val3}`;

            return {
              subareas: {
                /**
                 * サブエリアEXCELダウンロード
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_t3cxen['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_t3cxen['get']['resBody'], BasicHeaders, Methods_t3cxen['get']['status']>(prefix, `${prefix3}${PATH4}`, GET, option).blob(),
                /**
                 * サブエリアEXCELダウンロード
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_t3cxen['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_t3cxen['get']['resBody'], BasicHeaders, Methods_t3cxen['get']['status']>(prefix, `${prefix3}${PATH4}`, GET, option).blob().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_t3cxen['get']['query'] } | undefined) =>
                  `${prefix}${prefix3}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            };
          },
          /**
           * マスタダウンロード（エリア）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1gvlo7t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1gvlo7t['get']['resBody'], BasicHeaders, Methods_1gvlo7t['get']['status']>(prefix, PATH3, GET, option).blob(),
          /**
           * マスタダウンロード（エリア）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1gvlo7t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1gvlo7t['get']['resBody'], BasicHeaders, Methods_1gvlo7t['get']['status']>(prefix, PATH3, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1gvlo7t['get']['query'] } | undefined) =>
            `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        carrier_conditions: {
          /**
           * マスタダウンロード（搬送業者割）
           * @returns 成功
           */
          get: (option?: { query?: Methods_17u15hq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_17u15hq['get']['resBody'], BasicHeaders, Methods_17u15hq['get']['status']>(prefix, PATH5, GET, option).blob(),
          /**
           * マスタダウンロード（搬送業者割）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_17u15hq['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_17u15hq['get']['resBody'], BasicHeaders, Methods_17u15hq['get']['status']>(prefix, PATH5, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_17u15hq['get']['query'] } | undefined) =>
            `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        centers: {
          _center_id: (val3: number | string) => {
            const prefix3 = `${PATH6}/${val3}`;

            return {
              hospitals: {
                /**
                 * マスタダウンロード（病院）
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_h89pt6['get']['resBody'], BasicHeaders, Methods_h89pt6['get']['status']>(prefix, `${prefix3}${PATH7}`, GET, option).blob(),
                /**
                 * マスタダウンロード（病院）
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_h89pt6['get']['resBody'], BasicHeaders, Methods_h89pt6['get']['status']>(prefix, `${prefix3}${PATH7}`, GET, option).blob().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH7}`,
              },
              mortuaries: {
                /**
                 * マスタダウンロード（安置先）
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_5a3j1a['get']['resBody'], BasicHeaders, Methods_5a3j1a['get']['status']>(prefix, `${prefix3}${PATH8}`, GET, option).blob(),
                /**
                 * マスタダウンロード（安置先）
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_5a3j1a['get']['resBody'], BasicHeaders, Methods_5a3j1a['get']['status']>(prefix, `${prefix3}${PATH8}`, GET, option).blob().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH8}`,
              },
            };
          },
          /**
           * マスタダウンロード（葬祭センター）
           * @returns 成功
           */
          get: (option?: { query?: Methods_oqpsdt['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_oqpsdt['get']['resBody'], BasicHeaders, Methods_oqpsdt['get']['status']>(prefix, PATH6, GET, option).blob(),
          /**
           * マスタダウンロード（葬祭センター）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_oqpsdt['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_oqpsdt['get']['resBody'], BasicHeaders, Methods_oqpsdt['get']['status']>(prefix, PATH6, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_oqpsdt['get']['query'] } | undefined) =>
            `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        halls: {
          /**
           * マスタダウンロード（ホール）
           * @returns 成功
           */
          get: (option?: { query?: Methods_r3d1kv['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_r3d1kv['get']['resBody'], BasicHeaders, Methods_r3d1kv['get']['status']>(prefix, PATH9, GET, option).blob(),
          /**
           * マスタダウンロード（ホール）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_r3d1kv['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_r3d1kv['get']['resBody'], BasicHeaders, Methods_r3d1kv['get']['status']>(prefix, PATH9, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_r3d1kv['get']['query'] } | undefined) =>
            `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        japan_agricultural_branches: {
          /**
           * マスタダウンロード（JA支店）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1ndvyd0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ndvyd0['get']['resBody'], BasicHeaders, Methods_1ndvyd0['get']['status']>(prefix, PATH10, GET, option).blob(),
          /**
           * マスタダウンロード（JA支店）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1ndvyd0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ndvyd0['get']['resBody'], BasicHeaders, Methods_1ndvyd0['get']['status']>(prefix, PATH10, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1ndvyd0['get']['query'] } | undefined) =>
            `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        japan_agriculturals: {
          /**
           * マスタダウンロード（JA）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1ndru9w['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ndru9w['get']['resBody'], BasicHeaders, Methods_1ndru9w['get']['status']>(prefix, PATH11, GET, option).blob(),
          /**
           * マスタダウンロード（JA）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1ndru9w['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ndru9w['get']['resBody'], BasicHeaders, Methods_1ndru9w['get']['status']>(prefix, PATH11, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1ndru9w['get']['query'] } | undefined) =>
            `${prefix}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        partners: {
          /**
           * マスタダウンロード（契約社）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1azbn2y['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1azbn2y['get']['resBody'], BasicHeaders, Methods_1azbn2y['get']['status']>(prefix, PATH12, GET, option).blob(),
          /**
           * マスタダウンロード（契約社）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1azbn2y['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1azbn2y['get']['resBody'], BasicHeaders, Methods_1azbn2y['get']['status']>(prefix, PATH12, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1azbn2y['get']['query'] } | undefined) =>
            `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        phone_lines: {
          _phone_line_id: (val3: number | string) => {
            const prefix3 = `${PATH13}/${val3}`;

            return {
              attentions: {
                /**
                 * 留意点EXCELダウンロード
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_xvwcs0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_xvwcs0['get']['resBody'], BasicHeaders, Methods_xvwcs0['get']['status']>(prefix, `${prefix3}${PATH14}`, GET, option).blob(),
                /**
                 * 留意点EXCELダウンロード
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_xvwcs0['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_xvwcs0['get']['resBody'], BasicHeaders, Methods_xvwcs0['get']['status']>(prefix, `${prefix3}${PATH14}`, GET, option).blob().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_xvwcs0['get']['query'] } | undefined) =>
                  `${prefix}${prefix3}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            };
          },
          /**
           * マスタダウンロード（回線）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1sdohsv['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1sdohsv['get']['resBody'], BasicHeaders, Methods_1sdohsv['get']['status']>(prefix, PATH13, GET, option).blob(),
          /**
           * マスタダウンロード（回線）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1sdohsv['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1sdohsv['get']['resBody'], BasicHeaders, Methods_1sdohsv['get']['status']>(prefix, PATH13, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1sdohsv['get']['query'] } | undefined) =>
            `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        regions: {
          /**
           * マスタダウンロード（地区）
           * @returns 成功
           */
          get: (option?: { query?: Methods_1lvppy['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1lvppy['get']['resBody'], BasicHeaders, Methods_1lvppy['get']['status']>(prefix, PATH15, GET, option).blob(),
          /**
           * マスタダウンロード（地区）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_1lvppy['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1lvppy['get']['resBody'], BasicHeaders, Methods_1lvppy['get']['status']>(prefix, PATH15, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1lvppy['get']['query'] } | undefined) =>
            `${prefix}${PATH15}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        stores: {
          /**
           * マスタダウンロード（店舗）
           * @returns 成功
           */
          get: (option?: { query?: Methods_q5smer['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_q5smer['get']['resBody'], BasicHeaders, Methods_q5smer['get']['status']>(prefix, PATH16, GET, option).blob(),
          /**
           * マスタダウンロード（店舗）
           * @returns 成功
           */
          $get: (option?: { query?: Methods_q5smer['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_q5smer['get']['resBody'], BasicHeaders, Methods_q5smer['get']['status']>(prefix, PATH16, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_q5smer['get']['query'] } | undefined) =>
            `${prefix}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      summary: {
        /**
         * 店舗別集計表ダウンロードを行う。
         * @returns 成功
         */
        get: (option: { query: Methods_1w1bjs2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1w1bjs2['get']['resBody'], BasicHeaders, Methods_1w1bjs2['get']['status']>(prefix, PATH17, GET, option).blob(),
        /**
         * 店舗別集計表ダウンロードを行う。
         * @returns 成功
         */
        $get: (option: { query: Methods_1w1bjs2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1w1bjs2['get']['resBody'], BasicHeaders, Methods_1w1bjs2['get']['status']>(prefix, PATH17, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1w1bjs2['get']['query'] } | undefined) =>
          `${prefix}${PATH17}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      tasks: {
        _task_id: (val2: number) => {
          const prefix2 = `${PATH18}/${val2}`;

          return {
            /**
             * 注文書ダウンロードを行う
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1f5iie2['get']['resBody'], BasicHeaders, Methods_1f5iie2['get']['status']>(prefix, prefix2, GET, option).blob(),
            /**
             * 注文書ダウンロードを行う
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1f5iie2['get']['resBody'], BasicHeaders, Methods_1f5iie2['get']['status']>(prefix, prefix2, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 業務一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        get: (option?: { query?: Methods_15s9tzs['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_15s9tzs['get']['resBody'], BasicHeaders, Methods_15s9tzs['get']['status']>(prefix, PATH18, GET, option).blob(),
        /**
         * 業務一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        $get: (option?: { query?: Methods_15s9tzs['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_15s9tzs['get']['resBody'], BasicHeaders, Methods_15s9tzs['get']['status']>(prefix, PATH18, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_15s9tzs['get']['query'] } | undefined) =>
          `${prefix}${PATH18}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      users: {
        /**
         * ユーザーEXCELダウンロード
         * @returns 成功
         */
        get: (option?: { query?: Methods_hkcaqm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_hkcaqm['get']['resBody'], BasicHeaders, Methods_hkcaqm['get']['status']>(prefix, PATH19, GET, option).blob(),
        /**
         * ユーザーEXCELダウンロード
         * @returns 成功
         */
        $get: (option?: { query?: Methods_hkcaqm['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods_hkcaqm['get']['resBody'], BasicHeaders, Methods_hkcaqm['get']['status']>(prefix, PATH19, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_hkcaqm['get']['query'] } | undefined) =>
          `${prefix}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    funerals: {
      _funeral_id: (val1: number) => {
        const prefix1 = `${PATH20}/${val1}`;

        return {
          /**
           * 葬儀取得する
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_3g0ibe['get']['resBody'], BasicHeaders, Methods_3g0ibe['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 葬儀取得する
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_3g0ibe['get']['resBody'], BasicHeaders, Methods_3g0ibe['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 葬儀更新する
           * @param option.body - 葬儀更新時情報
           */
          put: (option: { body: Methods_3g0ibe['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_3g0ibe['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * 葬儀更新する
           * @param option.body - 葬儀更新時情報
           */
          $put: (option: { body: Methods_3g0ibe['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_3g0ibe['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * 葬儀削除する
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_3g0ibe['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 葬儀削除する
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_3g0ibe['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      /**
       * 葬儀一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods_14h2m12['get']['query'], config?: T | undefined }) =>
        fetch<Methods_14h2m12['get']['resBody'], BasicHeaders, Methods_14h2m12['get']['status']>(prefix, PATH20, GET, option).json(),
      /**
       * 葬儀一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods_14h2m12['get']['query'], config?: T | undefined }) =>
        fetch<Methods_14h2m12['get']['resBody'], BasicHeaders, Methods_14h2m12['get']['status']>(prefix, PATH20, GET, option).json().then(r => r.body),
      /**
       * 葬儀追加を行う
       * @param option.body - 葬儀追加時情報
       * @returns Created
       */
      post: (option: { body: Methods_14h2m12['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_14h2m12['post']['resBody'], BasicHeaders, Methods_14h2m12['post']['status']>(prefix, PATH20, POST, option).json(),
      /**
       * 葬儀追加を行う
       * @param option.body - 葬儀追加時情報
       * @returns Created
       */
      $post: (option: { body: Methods_14h2m12['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_14h2m12['post']['resBody'], BasicHeaders, Methods_14h2m12['post']['status']>(prefix, PATH20, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_14h2m12['get']['query'] } | undefined) =>
        `${prefix}${PATH20}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    login: {
      /**
       * ログインの試行を行う
       * @returns ログイン成功
       */
      post: (option: { body: Methods_idk8rz['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_idk8rz['post']['resBody'], BasicHeaders, Methods_idk8rz['post']['status']>(prefix, PATH21, POST, option).json(),
      /**
       * ログインの試行を行う
       * @returns ログイン成功
       */
      $post: (option: { body: Methods_idk8rz['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_idk8rz['post']['resBody'], BasicHeaders, Methods_idk8rz['post']['status']>(prefix, PATH21, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH21}`,
    },
    master: {
      areas: {
        _area_id: (val2: number) => {
          const prefix2 = `${PATH22}/${val2}`;

          return {
            subareas: {
              _subarea_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH4}/${val4}`;

                return {
                  /**
                   * サブエリアの取得を行う。
                   * @returns サブエリア取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_einugw['get']['resBody'], BasicHeaders, Methods_einugw['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * サブエリアの取得を行う。
                   * @returns サブエリア取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_einugw['get']['resBody'], BasicHeaders, Methods_einugw['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * サブエリアの更新を行う。
                   */
                  put: (option: { body: Methods_einugw['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_einugw['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * サブエリアの更新を行う。
                   */
                  $put: (option: { body: Methods_einugw['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_einugw['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * サブエリアの削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_einugw['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * サブエリアの削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_einugw['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * サブエリア一覧取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1930eo4['get']['resBody'], BasicHeaders, Methods_1930eo4['get']['status']>(prefix, `${prefix2}${PATH4}`, GET, option).json(),
              /**
               * サブエリア一覧取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1930eo4['get']['resBody'], BasicHeaders, Methods_1930eo4['get']['status']>(prefix, `${prefix2}${PATH4}`, GET, option).json().then(r => r.body),
              /**
               * サブエリアの追加を行う。
               * @returns サブエリア登録成功
               */
              post: (option: { body: Methods_1930eo4['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1930eo4['post']['resBody'], BasicHeaders, Methods_1930eo4['post']['status']>(prefix, `${prefix2}${PATH4}`, POST, option).json(),
              /**
               * サブエリアの追加を行う。
               * @returns サブエリア登録成功
               */
              $post: (option: { body: Methods_1930eo4['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1930eo4['post']['resBody'], BasicHeaders, Methods_1930eo4['post']['status']>(prefix, `${prefix2}${PATH4}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH4}`,
            },
            /**
             * エリアの取得を行う。
             * @returns ホール取得成功
             */
            get: (option?: { query?: Methods_6s0wk7['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_6s0wk7['get']['resBody'], BasicHeaders, Methods_6s0wk7['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * エリアの取得を行う。
             * @returns ホール取得成功
             */
            $get: (option?: { query?: Methods_6s0wk7['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_6s0wk7['get']['resBody'], BasicHeaders, Methods_6s0wk7['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * ホールの更新を行う。
             */
            put: (option: { body: Methods_6s0wk7['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_6s0wk7['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * ホールの更新を行う。
             */
            $put: (option: { body: Methods_6s0wk7['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_6s0wk7['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * エリアの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_6s0wk7['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * エリアの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_6s0wk7['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_6s0wk7['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          };
        },
        /**
         * エリア一覧取得を行う。このAPIはページングなし全件取得。
         * @returns OK
         */
        get: (option: { query: Methods_f8gur9['get']['query'], config?: T | undefined }) =>
          fetch<Methods_f8gur9['get']['resBody'], BasicHeaders, Methods_f8gur9['get']['status']>(prefix, PATH22, GET, option).json(),
        /**
         * エリア一覧取得を行う。このAPIはページングなし全件取得。
         * @returns OK
         */
        $get: (option: { query: Methods_f8gur9['get']['query'], config?: T | undefined }) =>
          fetch<Methods_f8gur9['get']['resBody'], BasicHeaders, Methods_f8gur9['get']['status']>(prefix, PATH22, GET, option).json().then(r => r.body),
        /**
         * エリアの追加を行う。
         * @returns エリア登録成功
         */
        post: (option: { body: Methods_f8gur9['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_f8gur9['post']['resBody'], BasicHeaders, Methods_f8gur9['post']['status']>(prefix, PATH22, POST, option).json(),
        /**
         * エリアの追加を行う。
         * @returns エリア登録成功
         */
        $post: (option: { body: Methods_f8gur9['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_f8gur9['post']['resBody'], BasicHeaders, Methods_f8gur9['post']['status']>(prefix, PATH22, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_f8gur9['get']['query'] } | undefined) =>
          `${prefix}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      centers: {
        _center_id: (val2: number) => {
          const prefix2 = `${PATH23}/${val2}`;

          return {
            hospitals: {
              _hospital_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH7}/${val4}`;

                return {
                  /**
                   * 病院の取得を行う。
                   * @returns 病院取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_18ahdeq['get']['resBody'], BasicHeaders, Methods_18ahdeq['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 病院の取得を行う。
                   * @returns 病院取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_18ahdeq['get']['resBody'], BasicHeaders, Methods_18ahdeq['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 病院の更新を行う。
                   */
                  put: (option: { body: Methods_18ahdeq['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_18ahdeq['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 病院の更新を行う。
                   */
                  $put: (option: { body: Methods_18ahdeq['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_18ahdeq['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 病院の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_18ahdeq['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 病院の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_18ahdeq['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 病院一覧取得を行う。
               * @returns OK
               */
              get: (option: { query: Methods_14r7ajj['get']['query'], config?: T | undefined }) =>
                fetch<Methods_14r7ajj['get']['resBody'], BasicHeaders, Methods_14r7ajj['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json(),
              /**
               * 病院一覧取得を行う。
               * @returns OK
               */
              $get: (option: { query: Methods_14r7ajj['get']['query'], config?: T | undefined }) =>
                fetch<Methods_14r7ajj['get']['resBody'], BasicHeaders, Methods_14r7ajj['get']['status']>(prefix, `${prefix2}${PATH7}`, GET, option).json().then(r => r.body),
              /**
               * 病院の追加を行う。
               * @returns 病院登録成功
               */
              post: (option: { body: Methods_14r7ajj['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_14r7ajj['post']['resBody'], BasicHeaders, Methods_14r7ajj['post']['status']>(prefix, `${prefix2}${PATH7}`, POST, option).json(),
              /**
               * 病院の追加を行う。
               * @returns 病院登録成功
               */
              $post: (option: { body: Methods_14r7ajj['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_14r7ajj['post']['resBody'], BasicHeaders, Methods_14r7ajj['post']['status']>(prefix, `${prefix2}${PATH7}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_14r7ajj['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            mortuaries: {
              _mortuary_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH8}/${val4}`;

                return {
                  /**
                   * 安置先取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_mz0s4b['get']['resBody'], BasicHeaders, Methods_mz0s4b['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 安置先取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_mz0s4b['get']['resBody'], BasicHeaders, Methods_mz0s4b['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 安置先更新する
                   * @param option.body - 安置先更新時情報
                   */
                  put: (option: { body: Methods_mz0s4b['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_mz0s4b['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 安置先更新する
                   * @param option.body - 安置先更新時情報
                   */
                  $put: (option: { body: Methods_mz0s4b['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_mz0s4b['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 安置先削除する
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_mz0s4b['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 安置先削除する
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_mz0s4b['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 安置先一覧取得を行う。
               * @returns OK
               */
              get: (option: { query: Methods_ri1fjl['get']['query'], config?: T | undefined }) =>
                fetch<Methods_ri1fjl['get']['resBody'], BasicHeaders, Methods_ri1fjl['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json(),
              /**
               * 安置先一覧取得を行う。
               * @returns OK
               */
              $get: (option: { query: Methods_ri1fjl['get']['query'], config?: T | undefined }) =>
                fetch<Methods_ri1fjl['get']['resBody'], BasicHeaders, Methods_ri1fjl['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json().then(r => r.body),
              /**
               * 安置先追加する
               * @param option.body - 安置先追加時情報
               * @returns Created
               */
              post: (option: { body: Methods_ri1fjl['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_ri1fjl['post']['resBody'], BasicHeaders, Methods_ri1fjl['post']['status']>(prefix, `${prefix2}${PATH8}`, POST, option).json(),
              /**
               * 安置先追加する
               * @param option.body - 安置先追加時情報
               * @returns Created
               */
              $post: (option: { body: Methods_ri1fjl['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_ri1fjl['post']['resBody'], BasicHeaders, Methods_ri1fjl['post']['status']>(prefix, `${prefix2}${PATH8}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_ri1fjl['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            stores: {
              /**
               * 葬祭センター配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_fw90za['get']['resBody'], BasicHeaders, Methods_fw90za['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * 葬祭センター配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_fw90za['get']['resBody'], BasicHeaders, Methods_fw90za['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`,
            },
            /**
             * 葬祭センターの取得を行う。
             * @returns 葬祭センター取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_101iddb['get']['resBody'], BasicHeaders, Methods_101iddb['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 葬祭センターの取得を行う。
             * @returns 葬祭センター取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_101iddb['get']['resBody'], BasicHeaders, Methods_101iddb['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 葬祭センターの更新を行う。
             */
            put: (option: { body: Methods_101iddb['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_101iddb['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 葬祭センターの更新を行う。
             */
            $put: (option: { body: Methods_101iddb['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_101iddb['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 葬祭センターの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_101iddb['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 葬祭センターの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_101iddb['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 葬祭センター一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_1at0fzp['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1at0fzp['get']['resBody'], BasicHeaders, Methods_1at0fzp['get']['status']>(prefix, PATH23, GET, option).json(),
        /**
         * 葬祭センター一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_1at0fzp['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1at0fzp['get']['resBody'], BasicHeaders, Methods_1at0fzp['get']['status']>(prefix, PATH23, GET, option).json().then(r => r.body),
        /**
         * 葬祭センターの追加を行う。
         * @returns 葬祭センター登録成功
         */
        post: (option: { body: Methods_1at0fzp['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1at0fzp['post']['resBody'], BasicHeaders, Methods_1at0fzp['post']['status']>(prefix, PATH23, POST, option).json(),
        /**
         * 葬祭センターの追加を行う。
         * @returns 葬祭センター登録成功
         */
        $post: (option: { body: Methods_1at0fzp['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1at0fzp['post']['resBody'], BasicHeaders, Methods_1at0fzp['post']['status']>(prefix, PATH23, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1at0fzp['get']['query'] } | undefined) =>
          `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      halls: {
        _hall_id: (val2: number) => {
          const prefix2 = `${PATH25}/${val2}`;

          return {
            /**
             * ホールの取得を行う。
             * @returns ホール取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_rtsuef['get']['resBody'], BasicHeaders, Methods_rtsuef['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * ホールの取得を行う。
             * @returns ホール取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_rtsuef['get']['resBody'], BasicHeaders, Methods_rtsuef['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * ホールの更新を行う。
             */
            put: (option: { body: Methods_rtsuef['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_rtsuef['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * ホールの更新を行う。
             */
            $put: (option: { body: Methods_rtsuef['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_rtsuef['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * ホールの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_rtsuef['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * ホールの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_rtsuef['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * ホール一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_1pi6nhv['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1pi6nhv['get']['resBody'], BasicHeaders, Methods_1pi6nhv['get']['status']>(prefix, PATH25, GET, option).json(),
        /**
         * ホール一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_1pi6nhv['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1pi6nhv['get']['resBody'], BasicHeaders, Methods_1pi6nhv['get']['status']>(prefix, PATH25, GET, option).json().then(r => r.body),
        /**
         * ホールの追加を行う。
         * @returns ホール登録成功
         */
        post: (option: { body: Methods_1pi6nhv['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1pi6nhv['post']['resBody'], BasicHeaders, Methods_1pi6nhv['post']['status']>(prefix, PATH25, POST, option).json(),
        /**
         * ホールの追加を行う。
         * @returns ホール登録成功
         */
        $post: (option: { body: Methods_1pi6nhv['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1pi6nhv['post']['resBody'], BasicHeaders, Methods_1pi6nhv['post']['status']>(prefix, PATH25, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1pi6nhv['get']['query'] } | undefined) =>
          `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      japan_agricultural_branches: {
        _japan_agricultural_branch_id: (val2: number) => {
          const prefix2 = `${PATH26}/${val2}`;

          return {
            /**
             * JA支店取得する
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_r3exkg['get']['resBody'], BasicHeaders, Methods_r3exkg['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * JA支店取得する
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_r3exkg['get']['resBody'], BasicHeaders, Methods_r3exkg['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * JA支店更新する
             * @param option.body - JA支店更新時情報
             */
            put: (option: { body: Methods_r3exkg['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_r3exkg['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * JA支店更新する
             * @param option.body - JA支店更新時情報
             */
            $put: (option: { body: Methods_r3exkg['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_r3exkg['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * JA支店削除する
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_r3exkg['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * JA支店削除する
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_r3exkg['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * JA支店一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_11aore8['get']['query'], config?: T | undefined }) =>
          fetch<Methods_11aore8['get']['resBody'], BasicHeaders, Methods_11aore8['get']['status']>(prefix, PATH26, GET, option).json(),
        /**
         * JA支店一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_11aore8['get']['query'], config?: T | undefined }) =>
          fetch<Methods_11aore8['get']['resBody'], BasicHeaders, Methods_11aore8['get']['status']>(prefix, PATH26, GET, option).json().then(r => r.body),
        /**
         * JA支店追加する
         * @param option.body - JA支店追加時情報
         * @returns Created
         */
        post: (option: { body: Methods_11aore8['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11aore8['post']['resBody'], BasicHeaders, Methods_11aore8['post']['status']>(prefix, PATH26, POST, option).json(),
        /**
         * JA支店追加する
         * @param option.body - JA支店追加時情報
         * @returns Created
         */
        $post: (option: { body: Methods_11aore8['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11aore8['post']['resBody'], BasicHeaders, Methods_11aore8['post']['status']>(prefix, PATH26, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_11aore8['get']['query'] } | undefined) =>
          `${prefix}${PATH26}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      japan_agriculturals: {
        _japan_agricultural_id: (val2: number) => {
          const prefix2 = `${PATH27}/${val2}`;

          return {
            /**
             * JA取得する
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_7kf0jx['get']['resBody'], BasicHeaders, Methods_7kf0jx['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * JA取得する
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_7kf0jx['get']['resBody'], BasicHeaders, Methods_7kf0jx['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * JA更新する
             * @param option.body - JA更新時情報
             */
            put: (option: { body: Methods_7kf0jx['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_7kf0jx['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * JA更新する
             * @param option.body - JA更新時情報
             */
            $put: (option: { body: Methods_7kf0jx['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_7kf0jx['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * JA削除する
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_7kf0jx['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * JA削除する
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_7kf0jx['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * JA一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_armftk['get']['query'], config?: T | undefined }) =>
          fetch<Methods_armftk['get']['resBody'], BasicHeaders, Methods_armftk['get']['status']>(prefix, PATH27, GET, option).json(),
        /**
         * JA一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_armftk['get']['query'], config?: T | undefined }) =>
          fetch<Methods_armftk['get']['resBody'], BasicHeaders, Methods_armftk['get']['status']>(prefix, PATH27, GET, option).json().then(r => r.body),
        /**
         * JA追加する
         * @param option.body - JA追加時情報
         * @returns Created
         */
        post: (option: { body: Methods_armftk['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_armftk['post']['resBody'], BasicHeaders, Methods_armftk['post']['status']>(prefix, PATH27, POST, option).json(),
        /**
         * JA追加する
         * @param option.body - JA追加時情報
         * @returns Created
         */
        $post: (option: { body: Methods_armftk['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_armftk['post']['resBody'], BasicHeaders, Methods_armftk['post']['status']>(prefix, PATH27, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_armftk['get']['query'] } | undefined) =>
          `${prefix}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      partners: {
        _partner_id: (val2: number) => {
          const prefix2 = `${PATH28}/${val2}`;

          return {
            phone_lines: {
              /**
               * 回線一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1wen3jc['get']['resBody'], BasicHeaders, Methods_1wen3jc['get']['status']>(prefix, `${prefix2}${PATH29}`, GET, option).json(),
              /**
               * 回線一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1wen3jc['get']['resBody'], BasicHeaders, Methods_1wen3jc['get']['status']>(prefix, `${prefix2}${PATH29}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH29}`,
            },
            /**
             * 契約社の取得を行う。
             * @returns 契約社取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_i2h7wn['get']['resBody'], BasicHeaders, Methods_i2h7wn['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 契約社の取得を行う。
             * @returns 契約社取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_i2h7wn['get']['resBody'], BasicHeaders, Methods_i2h7wn['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 契約社の更新を行う。
             */
            put: (option: { body: Methods_i2h7wn['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_i2h7wn['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 契約社の更新を行う。
             */
            $put: (option: { body: Methods_i2h7wn['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_i2h7wn['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 契約社の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_i2h7wn['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 契約社の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_i2h7wn['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 契約社一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_1ox7cf2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1ox7cf2['get']['resBody'], BasicHeaders, Methods_1ox7cf2['get']['status']>(prefix, PATH28, GET, option).json(),
        /**
         * 契約社一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_1ox7cf2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1ox7cf2['get']['resBody'], BasicHeaders, Methods_1ox7cf2['get']['status']>(prefix, PATH28, GET, option).json().then(r => r.body),
        /**
         * 契約社の追加を行う。
         * @returns 契約社登録成功
         */
        post: (option: { body: Methods_1ox7cf2['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1ox7cf2['post']['resBody'], BasicHeaders, Methods_1ox7cf2['post']['status']>(prefix, PATH28, POST, option).json(),
        /**
         * 契約社の追加を行う。
         * @returns 契約社登録成功
         */
        $post: (option: { body: Methods_1ox7cf2['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1ox7cf2['post']['resBody'], BasicHeaders, Methods_1ox7cf2['post']['status']>(prefix, PATH28, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1ox7cf2['get']['query'] } | undefined) =>
          `${prefix}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      phone_lines: {
        _phone_line_id: (val2: number) => {
          const prefix2 = `${PATH30}/${val2}`;

          return {
            areas: {
              /**
               * エリア一覧（業務画面用）取得を行う。希望葬儀式場で利用する
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_znfq1a['get']['resBody'], BasicHeaders, Methods_znfq1a['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json(),
              /**
               * エリア一覧（業務画面用）取得を行う。希望葬儀式場で利用する
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_znfq1a['get']['resBody'], BasicHeaders, Methods_znfq1a['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH31}`,
            },
            attentions: {
              _attention_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH14}/${val4}`;

                return {
                  /**
                   * 留意点取得を行う
                   * @returns 留意点取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_uikj3k['get']['resBody'], BasicHeaders, Methods_uikj3k['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 留意点取得を行う
                   * @returns 留意点取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_uikj3k['get']['resBody'], BasicHeaders, Methods_uikj3k['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 留意点を更新する
                   */
                  put: (option: { body: Methods_uikj3k['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_uikj3k['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 留意点を更新する
                   */
                  $put: (option: { body: Methods_uikj3k['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_uikj3k['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 留意点の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_uikj3k['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 留意点の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_uikj3k['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 留意点一覧取得を行う
               * @returns 留意点一覧取得成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1857mpr['get']['resBody'], BasicHeaders, Methods_1857mpr['get']['status']>(prefix, `${prefix2}${PATH14}`, GET, option).json(),
              /**
               * 留意点一覧取得を行う
               * @returns 留意点一覧取得成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1857mpr['get']['resBody'], BasicHeaders, Methods_1857mpr['get']['status']>(prefix, `${prefix2}${PATH14}`, GET, option).json().then(r => r.body),
              /**
               * 留意点の追加を行う
               * @returns 留意点追加成功
               */
              post: (option: { body: Methods_1857mpr['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1857mpr['post']['resBody'], BasicHeaders, Methods_1857mpr['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json(),
              /**
               * 留意点の追加を行う
               * @returns 留意点追加成功
               */
              $post: (option: { body: Methods_1857mpr['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1857mpr['post']['resBody'], BasicHeaders, Methods_1857mpr['post']['status']>(prefix, `${prefix2}${PATH14}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH14}`,
            },
            call_system_memos: {
              _call_system_memo_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH32}/${val4}`;

                return {
                  /**
                   * コールシステムメモを取得する
                   * @returns コールシステムメモ取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1a2svqw['get']['resBody'], BasicHeaders, Methods_1a2svqw['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * コールシステムメモを取得する
                   * @returns コールシステムメモ取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1a2svqw['get']['resBody'], BasicHeaders, Methods_1a2svqw['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * コールシステムメモを更新する
                   */
                  put: (option: { body: Methods_1a2svqw['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1a2svqw['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * コールシステムメモを更新する
                   */
                  $put: (option: { body: Methods_1a2svqw['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1a2svqw['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * コールシステムメモ一覧取得を行う
               * @returns コールシステムメモ取得成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_mvsdvo['get']['resBody'], BasicHeaders, Methods_mvsdvo['get']['status']>(prefix, `${prefix2}${PATH32}`, GET, option).json(),
              /**
               * コールシステムメモ一覧取得を行う
               * @returns コールシステムメモ取得成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_mvsdvo['get']['resBody'], BasicHeaders, Methods_mvsdvo['get']['status']>(prefix, `${prefix2}${PATH32}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH32}`,
            },
            carrier_conditions: {
              _carrier_condition_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH33}/${val4}`;

                return {
                  update_display_order: {
                    /**
                     * 搬送業者割表示順更新する
                     * @param option.body - 新しい搬送業者割表示順情報
                     */
                    put: (option: { body: Methods_ufabey['put']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods_ufabey['put']['status']>(prefix, `${prefix4}${PATH34}`, PUT, option).send(),
                    /**
                     * 搬送業者割表示順更新する
                     * @param option.body - 新しい搬送業者割表示順情報
                     */
                    $put: (option: { body: Methods_ufabey['put']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods_ufabey['put']['status']>(prefix, `${prefix4}${PATH34}`, PUT, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH34}`,
                  },
                  /**
                   * 搬送業者割取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_nilja0['get']['resBody'], BasicHeaders, Methods_nilja0['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 搬送業者割取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_nilja0['get']['resBody'], BasicHeaders, Methods_nilja0['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 搬送業者割更新する
                   * @param option.body - 搬送業者割更新時情報
                   */
                  put: (option: { body: Methods_nilja0['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_nilja0['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 搬送業者割更新する
                   * @param option.body - 搬送業者割更新時情報
                   */
                  $put: (option: { body: Methods_nilja0['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_nilja0['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 搬送業者割削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_nilja0['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 搬送業者割削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_nilja0['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 搬送業者割一覧を行う。
               * @returns 搬送業者割一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1h53rv['get']['resBody'], BasicHeaders, Methods_1h53rv['get']['status']>(prefix, `${prefix2}${PATH33}`, GET, option).json(),
              /**
               * 搬送業者割一覧を行う。
               * @returns 搬送業者割一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1h53rv['get']['resBody'], BasicHeaders, Methods_1h53rv['get']['status']>(prefix, `${prefix2}${PATH33}`, GET, option).json().then(r => r.body),
              /**
               * 搬送業者割追加を行う。
               * @returns 搬送業者割追加成功
               */
              post: (option: { body: Methods_1h53rv['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1h53rv['post']['resBody'], BasicHeaders, Methods_1h53rv['post']['status']>(prefix, `${prefix2}${PATH33}`, POST, option).json(),
              /**
               * 搬送業者割追加を行う。
               * @returns 搬送業者割追加成功
               */
              $post: (option: { body: Methods_1h53rv['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1h53rv['post']['resBody'], BasicHeaders, Methods_1h53rv['post']['status']>(prefix, `${prefix2}${PATH33}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH33}`,
            },
            carriers: {
              _carrier_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH35}/${val4}`;

                return {
                  /**
                   * 搬送業者の取得を行う。
                   * @returns 搬送業者取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1fdpwzk['get']['resBody'], BasicHeaders, Methods_1fdpwzk['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 搬送業者の取得を行う。
                   * @returns 搬送業者取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1fdpwzk['get']['resBody'], BasicHeaders, Methods_1fdpwzk['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 搬送業者の更新を行う。
                   */
                  put: (option: { body: Methods_1fdpwzk['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1fdpwzk['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 搬送業者の更新を行う。
                   */
                  $put: (option: { body: Methods_1fdpwzk['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1fdpwzk['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 搬送業者の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1fdpwzk['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 搬送業者の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1fdpwzk['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 搬送業者一覧取得を行う。
               * @returns OK
               */
              get: (option: { query: Methods_1y9h1vx['get']['query'], config?: T | undefined }) =>
                fetch<Methods_1y9h1vx['get']['resBody'], BasicHeaders, Methods_1y9h1vx['get']['status']>(prefix, `${prefix2}${PATH35}`, GET, option).json(),
              /**
               * 搬送業者一覧取得を行う。
               * @returns OK
               */
              $get: (option: { query: Methods_1y9h1vx['get']['query'], config?: T | undefined }) =>
                fetch<Methods_1y9h1vx['get']['resBody'], BasicHeaders, Methods_1y9h1vx['get']['status']>(prefix, `${prefix2}${PATH35}`, GET, option).json().then(r => r.body),
              /**
               * 搬送業者の追加を行う。
               * @returns 搬送業者登録成功
               */
              post: (option: { body: Methods_1y9h1vx['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1y9h1vx['post']['resBody'], BasicHeaders, Methods_1y9h1vx['post']['status']>(prefix, `${prefix2}${PATH35}`, POST, option).json(),
              /**
               * 搬送業者の追加を行う。
               * @returns 搬送業者登録成功
               */
              $post: (option: { body: Methods_1y9h1vx['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_1y9h1vx['post']['resBody'], BasicHeaders, Methods_1y9h1vx['post']['status']>(prefix, `${prefix2}${PATH35}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_1y9h1vx['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH35}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            halls: {
              /**
               * ホール一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { query?: Methods_s5xq20['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_s5xq20['get']['resBody'], BasicHeaders, Methods_s5xq20['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json(),
              /**
               * ホール一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { query?: Methods_s5xq20['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_s5xq20['get']['resBody'], BasicHeaders, Methods_s5xq20['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_s5xq20['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            japan_agriculturals: {
              _japan_agricultural_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH37}/${val4}`;

                return {
                  japan_agricultural_branches: {
                    /**
                     * JA支店一覧（業務画面用）取得を行う。
                     * @returns OK
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_1xzy7cu['get']['resBody'], BasicHeaders, Methods_1xzy7cu['get']['status']>(prefix, `${prefix4}${PATH38}`, GET, option).json(),
                    /**
                     * JA支店一覧（業務画面用）取得を行う。
                     * @returns OK
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods_1xzy7cu['get']['resBody'], BasicHeaders, Methods_1xzy7cu['get']['status']>(prefix, `${prefix4}${PATH38}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH38}`,
                  },
                };
              },
              /**
               * JA一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_psvpqz['get']['resBody'], BasicHeaders, Methods_psvpqz['get']['status']>(prefix, `${prefix2}${PATH37}`, GET, option).json(),
              /**
               * JA一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_psvpqz['get']['resBody'], BasicHeaders, Methods_psvpqz['get']['status']>(prefix, `${prefix2}${PATH37}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH37}`,
            },
            manuals: {
              _manual_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH39}/${val4}`;

                return {
                  /**
                   * マニュアル削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1p0k656['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * マニュアル削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1p0k656['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * マニュアル一覧を行う。
               * @returns マニュアル一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_157ax5z['get']['resBody'], BasicHeaders, Methods_157ax5z['get']['status']>(prefix, `${prefix2}${PATH39}`, GET, option).json(),
              /**
               * マニュアル一覧を行う。
               * @returns マニュアル一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_157ax5z['get']['resBody'], BasicHeaders, Methods_157ax5z['get']['status']>(prefix, `${prefix2}${PATH39}`, GET, option).json().then(r => r.body),
              /**
               * マニュアル追加を行う。
               */
              post: (option: { body: Methods_157ax5z['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_157ax5z['post']['status']>(prefix, `${prefix2}${PATH39}`, POST, option).send(),
              /**
               * マニュアル追加を行う。
               */
              $post: (option: { body: Methods_157ax5z['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_157ax5z['post']['status']>(prefix, `${prefix2}${PATH39}`, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH39}`,
            },
            mortuaries: {
              /**
               * 安置先一覧（業務画面用）取得を行う。画面は、該当エリアが指定された場合はdeceased_addressを付与してリクエストする。該当地区が指定された場合はdeceased_addressを未指定にする。
               * @returns OK
               */
              get: (option?: { query?: Methods_1yhs3hd['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_1yhs3hd['get']['resBody'], BasicHeaders, Methods_1yhs3hd['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json(),
              /**
               * 安置先一覧（業務画面用）取得を行う。画面は、該当エリアが指定された場合はdeceased_addressを付与してリクエストする。該当地区が指定された場合はdeceased_addressを未指定にする。
               * @returns OK
               */
              $get: (option?: { query?: Methods_1yhs3hd['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_1yhs3hd['get']['resBody'], BasicHeaders, Methods_1yhs3hd['get']['status']>(prefix, `${prefix2}${PATH8}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_1yhs3hd['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            phone_line_partners: {
              _phone_line_partner_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH40}/${val4}`;

                return {
                  /**
                   * 提携業者取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_jdl2ui['get']['resBody'], BasicHeaders, Methods_jdl2ui['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 提携業者取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_jdl2ui['get']['resBody'], BasicHeaders, Methods_jdl2ui['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 提携業者更新する
                   * @param option.body - 提携業者更新時情報
                   */
                  put: (option: { body: Methods_jdl2ui['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_jdl2ui['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 提携業者更新する
                   * @param option.body - 提携業者更新時情報
                   */
                  $put: (option: { body: Methods_jdl2ui['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_jdl2ui['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 提携業者削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_jdl2ui['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 提携業者削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_jdl2ui['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 提携業者一覧を行う。
               * @returns 提携業者一覧成功
               */
              get: (option: { query: Methods_13vl0sn['get']['query'], config?: T | undefined }) =>
                fetch<Methods_13vl0sn['get']['resBody'], BasicHeaders, Methods_13vl0sn['get']['status']>(prefix, `${prefix2}${PATH40}`, GET, option).json(),
              /**
               * 提携業者一覧を行う。
               * @returns 提携業者一覧成功
               */
              $get: (option: { query: Methods_13vl0sn['get']['query'], config?: T | undefined }) =>
                fetch<Methods_13vl0sn['get']['resBody'], BasicHeaders, Methods_13vl0sn['get']['status']>(prefix, `${prefix2}${PATH40}`, GET, option).json().then(r => r.body),
              /**
               * 提携業者追加を行う。
               */
              post: (option: { body: Methods_13vl0sn['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_13vl0sn['post']['status']>(prefix, `${prefix2}${PATH40}`, POST, option).send(),
              /**
               * 提携業者追加を行う。
               */
              $post: (option: { body: Methods_13vl0sn['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_13vl0sn['post']['status']>(prefix, `${prefix2}${PATH40}`, POST, option).send().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_13vl0sn['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            report_destinations: {
              _report_destination_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH41}/${val4}`;

                return {
                  /**
                   * 報告先取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1qbrw5o['get']['resBody'], BasicHeaders, Methods_1qbrw5o['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 報告先取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1qbrw5o['get']['resBody'], BasicHeaders, Methods_1qbrw5o['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 報告先更新する
                   * @param option.body - 報告先更新時情報
                   */
                  put: (option: { body: Methods_1qbrw5o['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1qbrw5o['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 報告先更新する
                   * @param option.body - 報告先更新時情報
                   */
                  $put: (option: { body: Methods_1qbrw5o['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1qbrw5o['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 報告先削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1qbrw5o['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 報告先削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods_1qbrw5o['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 報告先一覧を行う。
               * @returns 報告先一覧成功
               */
              get: (option: { query: Methods_hug3gi['get']['query'], config?: T | undefined }) =>
                fetch<Methods_hug3gi['get']['resBody'], BasicHeaders, Methods_hug3gi['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json(),
              /**
               * 報告先一覧を行う。
               * @returns 報告先一覧成功
               */
              $get: (option: { query: Methods_hug3gi['get']['query'], config?: T | undefined }) =>
                fetch<Methods_hug3gi['get']['resBody'], BasicHeaders, Methods_hug3gi['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json().then(r => r.body),
              /**
               * 報告先追加を行う。
               */
              post: (option: { body: Methods_hug3gi['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_hug3gi['post']['status']>(prefix, `${prefix2}${PATH41}`, POST, option).send(),
              /**
               * 報告先追加を行う。
               */
              $post: (option: { body: Methods_hug3gi['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_hug3gi['post']['status']>(prefix, `${prefix2}${PATH41}`, POST, option).send().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_hug3gi['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH41}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            stores: {
              /**
               * 回線配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_g2msg6['get']['resBody'], BasicHeaders, Methods_g2msg6['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * 回線配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_g2msg6['get']['resBody'], BasicHeaders, Methods_g2msg6['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`,
            },
            /**
             * 回線の取得を行う。
             * @returns 回線取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_2l6msf['get']['resBody'], BasicHeaders, Methods_2l6msf['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 回線の取得を行う。
             * @returns 回線取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_2l6msf['get']['resBody'], BasicHeaders, Methods_2l6msf['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 回線の更新を行う。
             */
            put: (option: { body: Methods_2l6msf['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_2l6msf['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 回線の更新を行う。
             */
            $put: (option: { body: Methods_2l6msf['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_2l6msf['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 回線の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_2l6msf['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 回線の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_2l6msf['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 回線一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_11nfbyz['get']['query'], config?: T | undefined }) =>
          fetch<Methods_11nfbyz['get']['resBody'], BasicHeaders, Methods_11nfbyz['get']['status']>(prefix, PATH30, GET, option).json(),
        /**
         * 回線一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_11nfbyz['get']['query'], config?: T | undefined }) =>
          fetch<Methods_11nfbyz['get']['resBody'], BasicHeaders, Methods_11nfbyz['get']['status']>(prefix, PATH30, GET, option).json().then(r => r.body),
        /**
         * 回線の追加を行う。
         * @returns 回線登録成功
         */
        post: (option: { body: Methods_11nfbyz['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11nfbyz['post']['resBody'], BasicHeaders, Methods_11nfbyz['post']['status']>(prefix, PATH30, POST, option).json(),
        /**
         * 回線の追加を行う。
         * @returns 回線登録成功
         */
        $post: (option: { body: Methods_11nfbyz['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_11nfbyz['post']['resBody'], BasicHeaders, Methods_11nfbyz['post']['status']>(prefix, PATH30, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_11nfbyz['get']['query'] } | undefined) =>
          `${prefix}${PATH30}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      regions: {
        _region_id: (val2: number) => {
          const prefix2 = `${PATH42}/${val2}`;

          return {
            /**
             * 地区の取得を行う。
             * @returns 地区取得成功
             */
            get: (option?: { query?: Methods_1i7dnf1['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1i7dnf1['get']['resBody'], BasicHeaders, Methods_1i7dnf1['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 地区の取得を行う。
             * @returns 地区取得成功
             */
            $get: (option?: { query?: Methods_1i7dnf1['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1i7dnf1['get']['resBody'], BasicHeaders, Methods_1i7dnf1['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 地区の更新を行う。
             */
            put: (option: { body: Methods_1i7dnf1['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1i7dnf1['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 地区の更新を行う。
             */
            $put: (option: { body: Methods_1i7dnf1['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1i7dnf1['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 地区の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_1i7dnf1['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 地区の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_1i7dnf1['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1i7dnf1['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          };
        },
        /**
         * 地区一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_1s750dm['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1s750dm['get']['resBody'], BasicHeaders, Methods_1s750dm['get']['status']>(prefix, PATH42, GET, option).json(),
        /**
         * 地区一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_1s750dm['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1s750dm['get']['resBody'], BasicHeaders, Methods_1s750dm['get']['status']>(prefix, PATH42, GET, option).json().then(r => r.body),
        /**
         * 地区の追加を行う。
         * @returns 地区登録成功
         */
        post: (option: { body: Methods_1s750dm['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1s750dm['post']['resBody'], BasicHeaders, Methods_1s750dm['post']['status']>(prefix, PATH42, POST, option).json(),
        /**
         * 地区の追加を行う。
         * @returns 地区登録成功
         */
        $post: (option: { body: Methods_1s750dm['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_1s750dm['post']['resBody'], BasicHeaders, Methods_1s750dm['post']['status']>(prefix, PATH42, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1s750dm['get']['query'] } | undefined) =>
          `${prefix}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      stores: {
        _store_id: (val2: number) => {
          const prefix2 = `${PATH43}/${val2}`;

          return {
            /**
             * 店舗の取得を行う。
             * @returns 店舗取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_12fgb13['get']['resBody'], BasicHeaders, Methods_12fgb13['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 店舗の取得を行う。
             * @returns 店舗取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_12fgb13['get']['resBody'], BasicHeaders, Methods_12fgb13['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 店舗の更新を行う。
             */
            put: (option: { body: Methods_12fgb13['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_12fgb13['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 店舗の更新を行う。
             */
            $put: (option: { body: Methods_12fgb13['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_12fgb13['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 店舗の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_12fgb13['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 店舗の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods_12fgb13['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 店舗一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods_12xkumf['get']['query'], config?: T | undefined }) =>
          fetch<Methods_12xkumf['get']['resBody'], BasicHeaders, Methods_12xkumf['get']['status']>(prefix, PATH43, GET, option).json(),
        /**
         * 店舗一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods_12xkumf['get']['query'], config?: T | undefined }) =>
          fetch<Methods_12xkumf['get']['resBody'], BasicHeaders, Methods_12xkumf['get']['status']>(prefix, PATH43, GET, option).json().then(r => r.body),
        /**
         * 店舗の追加を行う。
         * @returns 店舗登録成功
         */
        post: (option: { body: Methods_12xkumf['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_12xkumf['post']['resBody'], BasicHeaders, Methods_12xkumf['post']['status']>(prefix, PATH43, POST, option).json(),
        /**
         * 店舗の追加を行う。
         * @returns 店舗登録成功
         */
        $post: (option: { body: Methods_12xkumf['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_12xkumf['post']['resBody'], BasicHeaders, Methods_12xkumf['post']['status']>(prefix, PATH43, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_12xkumf['get']['query'] } | undefined) =>
          `${prefix}${PATH43}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    pdf: {
      tasks: {
        _task_id: (val2: number) => {
          const prefix2 = `${PATH44}/${val2}`;

          return {
            /**
             * 業務の印刷を行う
             * @returns 印刷成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_hstpwj['get']['resBody'], BasicHeaders, Methods_hstpwj['get']['status']>(prefix, prefix2, GET, option).blob(),
            /**
             * 業務の印刷を行う
             * @returns 印刷成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_hstpwj['get']['resBody'], BasicHeaders, Methods_hstpwj['get']['status']>(prefix, prefix2, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
    },
    tasks: {
      _task_id: (val1: number) => {
        const prefix1 = `${PATH45}/${val1}`;

        return {
          /**
           * 業務取得する
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_dizqkm['get']['resBody'], BasicHeaders, Methods_dizqkm['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 業務取得する
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_dizqkm['get']['resBody'], BasicHeaders, Methods_dizqkm['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 業務更新する
           * @param option.body - 業務更新時情報
           */
          put: (option: { body: Methods_dizqkm['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_dizqkm['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * 業務更新する
           * @param option.body - 業務更新時情報
           */
          $put: (option: { body: Methods_dizqkm['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_dizqkm['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * 業務削除する
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_dizqkm['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 業務削除する
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_dizqkm['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      /**
       * 業務一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods_w6o6q4['get']['query'], config?: T | undefined }) =>
        fetch<Methods_w6o6q4['get']['resBody'], BasicHeaders, Methods_w6o6q4['get']['status']>(prefix, PATH45, GET, option).json(),
      /**
       * 業務一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods_w6o6q4['get']['query'], config?: T | undefined }) =>
        fetch<Methods_w6o6q4['get']['resBody'], BasicHeaders, Methods_w6o6q4['get']['status']>(prefix, PATH45, GET, option).json().then(r => r.body),
      /**
       * 業務追加する
       * @param option.body - 業務追加時情報
       * @returns Created
       */
      post: (option: { body: Methods_w6o6q4['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_w6o6q4['post']['resBody'], BasicHeaders, Methods_w6o6q4['post']['status']>(prefix, PATH45, POST, option).json(),
      /**
       * 業務追加する
       * @param option.body - 業務追加時情報
       * @returns Created
       */
      $post: (option: { body: Methods_w6o6q4['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_w6o6q4['post']['resBody'], BasicHeaders, Methods_w6o6q4['post']['status']>(prefix, PATH45, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_w6o6q4['get']['query'] } | undefined) =>
        `${prefix}${PATH45}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
    user: {
      /**
       * 認証済みのユーザー情報を取得
       * @returns logged in
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_tli9od['get']['resBody'], BasicHeaders, Methods_tli9od['get']['status']>(prefix, PATH46, GET, option).json(),
      /**
       * 認証済みのユーザー情報を取得
       * @returns logged in
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_tli9od['get']['resBody'], BasicHeaders, Methods_tli9od['get']['status']>(prefix, PATH46, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH46}`,
    },
    user_roles: {
      /**
       * ユーザロール一覧取得を行う。
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_c5f1rd['get']['resBody'], BasicHeaders, Methods_c5f1rd['get']['status']>(prefix, PATH47, GET, option).json(),
      /**
       * ユーザロール一覧取得を行う。
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods_c5f1rd['get']['resBody'], BasicHeaders, Methods_c5f1rd['get']['status']>(prefix, PATH47, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH47}`,
    },
    users: {
      _user_id: (val1: number) => {
        const prefix1 = `${PATH48}/${val1}`;

        return {
          /**
           * ユーザの編集を行う
           */
          put: (option: { body: Methods_8ls8pm['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_8ls8pm['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * ユーザの編集を行う
           */
          $put: (option: { body: Methods_8ls8pm['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_8ls8pm['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * ユーザ詳細取得を行う
           * @returns 詳細取得成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_8ls8pm['get']['resBody'], BasicHeaders, Methods_8ls8pm['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * ユーザ詳細取得を行う
           * @returns 詳細取得成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_8ls8pm['get']['resBody'], BasicHeaders, Methods_8ls8pm['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * ユーザの削除を行う
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_8ls8pm['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * ユーザの削除を行う
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_8ls8pm['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
      /**
       * ユーザの登録を行う
       * @returns Created
       */
      post: (option: { body: Methods_1xhiioa['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1xhiioa['post']['resBody'], BasicHeaders, Methods_1xhiioa['post']['status']>(prefix, PATH48, POST, option).json(),
      /**
       * ユーザの登録を行う
       * @returns Created
       */
      $post: (option: { body: Methods_1xhiioa['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1xhiioa['post']['resBody'], BasicHeaders, Methods_1xhiioa['post']['status']>(prefix, PATH48, POST, option).json().then(r => r.body),
      /**
       * ユーザ一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods_1xhiioa['get']['query'], config?: T | undefined }) =>
        fetch<Methods_1xhiioa['get']['resBody'], BasicHeaders, Methods_1xhiioa['get']['status']>(prefix, PATH48, GET, option).json(),
      /**
       * ユーザ一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods_1xhiioa['get']['query'], config?: T | undefined }) =>
        fetch<Methods_1xhiioa['get']['resBody'], BasicHeaders, Methods_1xhiioa['get']['status']>(prefix, PATH48, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods_1xhiioa['get']['query'] } | undefined) =>
        `${prefix}${PATH48}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
